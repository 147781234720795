<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="6">
          <v-simple-table class="mb-4 mt-4">
            <tbody>
              <tr>
                <th>Agreement Date</th>
                <td>
                  <div v-if="dog.fch_agreement_date">
                    {{ formatDate(dog.fch_agreement_date) }}
                  </div>
                </td>
              </tr>
            </tbody>
          </v-simple-table>

          <v-card outlined class="mb-6">
            <v-toolbar flat dark dense color="accent">
              <v-toolbar-title>Contact</v-toolbar-title>
            </v-toolbar>

            <v-simple-table class="mb-4 mt-4" v-if="dog.fch_contact">
              <tbody>
                <tr>
                  <td>
                    <h3>
                      {{ dog.fch_contact.full_name }}
                    </h3>
                    <div v-if="dog.fch_contact.addresses[0]" class="mb-4">
                      {{ dog.fch_contact.addresses[0].line_1 }}<br />
                      <div v-if="dog.fch_contact.addresses[0].line_2">
                        {{ dog.fch_contact.addresses[0].line_2 }}<br />
                      </div>
                      <div v-if="dog.fch_contact.addresses[0].line_3">
                        {{ dog.fch_contact.addresses[0].line_3 }}<br />
                      </div>
                      <div v-if="dog.fch_contact.addresses[0].city">
                        {{ dog.fch_contact.addresses[0].city }}<br />
                      </div>

                      {{ dog.fch_contact.addresses[0].county }}<br />
                      {{ dog.fch_contact.addresses[0].postcode }}<br />
                    </div>

                    <p v-if="dog.fch_contact.email">
                      <a :href="`mailto:${dog.fch_contact.email}`">
                        {{ dog.fch_contact.email }}
                      </a>
                      <br />
                      Daytime Telephone:
                      {{ dog.fch_contact.daytime_telephone }}
                      <br />
                      Evening Telephone:
                      {{ dog.fch_contact.evening_telephone }}
                      <br />Mobile: {{ dog.fch_contact.phone }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-card outlined class="mb-6">
            <v-btn
              v-if="dog.fch_contact"
              small
              depressed
              color="green lighten-4 green--text"
              class="mr-2"
              :to="{
                name: 'module-lhl-customers-individual',
                params: { customerId: dog.fch_contact.id },
              }"
            >
              Go To Contact
            </v-btn>

            <v-btn
              v-else
              small
              depressed
              color="blue lighten-4 blue--text"
              class="mr-2"
            >
              Select Contact
            </v-btn>

            <v-btn
              v-if="dog.fch_contact"
              small
              depressed
              @click="openDelete(dog.fch_contact)"
              color="red lighten-4 red--text"
            >
              Remove Contact
            </v-btn>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog
        scrollable
        v-model="deleteContact.dialog"
        persistent
        max-width="400"
      >
        <v-card>
          <v-card-title class="headline">Remove Contact</v-card-title>
          <v-card-text>Are you sure you wish to remove?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" depressed @click="resetDelete"
              >No, cancel</v-btn
            >
            <v-btn
              color="success"
              depressed
              :loading="deleteContact.loading"
              @click="saveDelete"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      deleteContact: {
        dialog: false,
        customer: {},
        loading: false,
      },

      fields: {},
    };
  },

  computed: {
    dog() {
      return this.$store.getters["lhl/dogs/get"];
    },
  },

  methods: {
    openDelete(dog) {
      this.deleteContact.dog = dog;
      this.deleteContact.dialog = true;
    },

    resetDelete() {
      this.deleteContact.dialog = false;
      this.deleteContact.dog = {};
      this.deleteContact.loading = false;
    },

    saveDelete() {
      this.deleteContact.loading = true;

      let payload = {
        appId: this.$route.params.id,
        dogId: this.$route.params.dogId,
        fields: this.fields,
      };

      payload.fields.formName = "remove-contact";

      this.$store
        .dispatch("lhl/dogs/saveBasic", payload)
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteContact.loading = false;
        });
    },
  },
};
</script>
